/* 404 Page not found error */

import { Button, Center, Icon, Stack, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import * as React from "react"
import { FaHome, FaSadCry } from "react-icons/fa"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Page Not Found" />
    <Center>
      <Stack justify="center" align="center" spacing={8} minH="50vh">
        <Icon as={FaSadCry} w={20} h={20} color="cyan.600" />
        <Stack spacing={6} px={2}>
          <Text fontWeight="semibold" fontSize="2xl" as="h1" textAlign="center">
            404: Page Not Found
          </Text>
          <Text textAlign="center">
            Oops! The page you are looking for does not exist or is temporarily
            unavailable.
          </Text>
        </Stack>
        <Button
          leftIcon={<FaHome />}
          as={GatsbyLink}
          to="/"
          colorScheme="yellow"
        >
          Go to Homepage
        </Button>
      </Stack>
    </Center>
  </>
)

export default NotFoundPage
